let baseUri: string = ''

// console.warn('env Status', process.env.REACT_APP_STATUS)

switch (process.env.REACT_APP_STATUS) {
  case 'PROD':
    if (process.env.REACT_APP_PROD_BASE_URI === undefined)
      throw 'ERROR Misconfigured .env file'
    // console.warn('Using production URIs')
    baseUri = process.env.REACT_APP_PROD_BASE_URI
    break
  case 'DEV':
    if (process.env.REACT_APP_DEV_BASE_URI === undefined)
      throw 'ERROR Misconfigured .env file'
    console.warn('Using dev URIs')
    baseUri = process.env.REACT_APP_DEV_BASE_URI
    break
  default:
    alert('fatal configuration error')
    throw 'ERROR Misconfigured .env file'
}

export function invokeGet(relativeUri: string, callback: any) {
  console.log('GET: ', baseUri + relativeUri)
  fetch(baseUri + relativeUri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  })
    .then((x) => {
      if (!x.ok) {
        throw 'GET failed'
      } else if (x.status === 202) {
        // console.log('Got status code 202, processing request')
        return { pending: true } // handle accepted
      }
      return x.json()
    })
    .then((x) => {
      callback(x)
    })
}

export function invokePost(relativeUri: string, body: any, callback: any) {
  console.log('POST: ', baseUri + relativeUri, 'body: ', body)
  fetch(baseUri + relativeUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(body),
  })
    .then((x) => {
      if (!x.ok) {
        throw 'POST failed'
      } else if (x.status === 202) {
        // console.log('Got status code 202, processing request')
        return { pending: true } // handle accepted
      }
      return x.json()
    })
    .then((x) => {
      callback(x)
    })
}
