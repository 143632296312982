import {
  Button,
  Space,
  Text,
  Accordion,
  Notification,
  Pagination,
  Loader,
  Divider,
  TextInput,
  UnstyledButton,
} from '@mantine/core'
import { Ref, useEffect, useRef, useState } from 'react'
import { IconCalendar, IconUsers, IconSitemap } from '@tabler/icons'
import UsernamePicker from './components/UsernamePicker/UsernamePicker'
import SitePicker from './components/SitePicker/SitePicker'
import WLSite from './types/WLSite'
import { invokeGet, invokePost } from './util/HttpHelper'
import LogItemDisplay from './components/LogItemDisplay/LogItemDisplay'
import NotificationDisplay from './components/NotificationDisplay/NotificationDisplay'
import { IconCloudDownload } from '@tabler/icons'
import { ILogItem } from './components/LogItemDisplay/LogItem'
import ConversationDisplay from './components/ConversationDisplay/ConversationDisplay'
import RawTimeEntry from './components/TimePicker/RawTimeEntry'
import RaccoonLogo from './components/RaccoonLogo/RaccoonLogo'
import './ConversationBuilder.css'

interface IConversationBuilderProps {
  queryString: any
}

function getEmotes(siteName: string, cbx: any) {
  invokeGet('WLSite/Emote?siteName=' + siteName, (x: any) => {
    cbx(x)
  })
}

function ConversationBuilder(props: IConversationBuilderProps) {
  let timeRefernce = new Date()
  const timeDiff = timeRefernce.getTimezoneOffset() / 60

  let [siteSelected, setSiteSelected] = useState<WLSite | null>(null)
  let [usersSelected, setUsersSelected] = useState<string[]>([])
  let [startDate, setStartDate] = useState<Date>(() => {
    let date = new Date(Date.now())

    date.setHours(0)
    date.setMinutes(-date.getTimezoneOffset())
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  })
  let [endDate, setEndDate] = useState<Date>(new Date(Date.now()))
  let [siteUsers, setSiteUsers] = useState<string[]>([])
  let [siteEmotes, setSiteEmotes] = useState<string[]>([])
  let [messages, setMessages] = useState<ILogItem[]>([])
  let [conversationMessages, setConversationMessages] = useState<ILogItem[]>([])
  let [queryCount, setQueryCount] = useState<number>(0)
  let [notifications, setNotifications] = useState<any[]>([])
  let [activePage, setPage] = useState(1)
  let [activeAccordian, setActiveAccordian] = useState<string>('WLSite')
  let [highlightTerm, setHighlightTerm] = useState<string | null>(null)

  const highlightBoxRef = useRef<HTMLInputElement>(null)

  // console.log('SELECTED USERS', usersSelected)

  const pageIncrement = 100

  // conversation selection modification
  const addConversation = (message: ILogItem) => {
    if (conversationMessages.includes(message)) return

    let newArr = [...conversationMessages, message]

    newArr.sort((a, b) => {
      if (a.time < b.time) return -1
      if (a.time >= b.time) return 1
      return 0
    })

    setConversationMessages(newArr)
  }

  const removeFromConversation = (message: ILogItem) => {
    let tmpConvo: ILogItem[] = conversationMessages.filter(
      (item) => item.hash !== message.hash,
    )
    setConversationMessages(tmpConvo)
  }

  const modifyConversation = (message: ILogItem, remove: boolean = false) => {
    if (remove) removeFromConversation(message)
    else addConversation(message)
  }

  const clearConversation = () => {
    setConversationMessages([])
  }

  const updateHighlight = () =>
    setHighlightTerm(
      highlightBoxRef.current ? highlightBoxRef.current.value : null,
    )

  // refresh emotes as site changes
  useEffect(() => {
    if (siteSelected === null) {
      setSiteEmotes([])
      return
    }
    getEmotes(siteSelected.name, (x: any) => {
      setSiteEmotes(x)
    })
  }, [siteSelected])

  useEffect(() => {
    if (siteSelected === null || startDate === null || endDate === null) {
      return
    }

    doLogSearch()
  }, [activePage])

  useEffect(() => {
    if (siteSelected !== null) setActiveAccordian('dates')
  }, [siteSelected])

  let disableSearchButton: boolean =
    siteSelected === null || startDate === null || endDate === null

  const searchUsers = () => {
    if (siteSelected === null) return

    let URI = 'ChatLog'
    let URIdecoration =
      '?startDate=' +
      startDate.toISOString() +
      '&endDate=' +
      endDate.toISOString() +
      '&siteName=' +
      siteSelected.name +
      '&page=' +
      activePage

    let body = {
      usernames: usersSelected,
    }

    // console.log('Getting messages: ' + URI + URIdecoration)
    // console.log('message request body', body)

    invokePost(URI + URIdecoration, body, (x: any) => {
      // console.log('Got search response', x)
      if (x['pending']) {
        let key = 'Notif-' + Math.floor(Math.random() * 10000)
        setTimeout(() => {
          setNotifications([])
          doLogSearch()
        }, 5000 + notifications.length) // clear notification after time
        setNotifications([
          <Notification
            icon={<IconCloudDownload />}
            loading
            title="Request Submitted"
            key={key}
            disallowClose
          >
            <div className="NotificationBody">
              <div className="NotificationText">
                We didn't have logs between {startDate?.toLocaleString()} and{' '}
                {endDate?.toLocaleString()}. We will try again shortly...
              </div>
            </div>
          </Notification>,
          ...notifications,
        ])
      } else {
        setMessages(x.items)

        setQueryCount(x.totalItems)

        console.log('Num messages received: ', x.totalItems)

        /*let queryCountURI = 'ChatLog/MessageCount'

        invokeGet(queryCountURI + URIdecoration, (x: number) => {
          setQueryCount(x)
        })*/
      }
    })
  }

  const doFreshSearch = () => {
    setPage(1)
    setMessages([])
    setQueryCount(0)
    doLogSearch()
  }

  const doLogSearch = () => {
    if (siteSelected === null || startDate === null || endDate === null) {
      alert(
        'Something which should not happen has indeed happened! Please say a quick prayer and reload the page.',
      )
      return
    }

    if (usersSelected.length > 0) {
      searchUsers()
      return
    }

    let URI = 'ChatLog'
    let URIdecoration =
      '?startDate=' +
      startDate.toISOString() +
      '&endDate=' +
      endDate.toISOString() +
      '&siteName=' +
      siteSelected.name +
      '&page=' +
      activePage

    // console.log('Getting messages: ' + URI + URIdecoration)

    invokeGet(URI + URIdecoration, (x: any) => {
      // console.log('Got search response', x)
      if (x['pending']) {
        let key = 'Notif-' + Math.floor(Math.random() * 10000)
        setTimeout(() => {
          setNotifications([])
          doLogSearch()
        }, 5000 + notifications.length) // clear notification after time
        setNotifications([
          <Notification
            icon={<IconCloudDownload />}
            loading
            title="Request Submitted"
            key={key}
            disallowClose
          >
            <div className="NotificationBody">
              <div className="NotificationText">
                We didn't have logs between {startDate?.toLocaleString()} and{' '}
                {endDate?.toLocaleString()}. We will try again shortly...
              </div>
            </div>
          </Notification>,
          ...notifications,
        ])
      } else {
        setMessages(x.items)
        setQueryCount(x.totalItems)

        // let queryCountURI = 'ChatLog/MessageCount'

        // invokeGet(queryCountURI + URIdecoration, (x: number) => {
        //  setQueryCount(x)
        // })
      }
    })
  }

  const saveConversation = (conversation: any) => {
    console.log(conversation)

    if (conversation && conversation.length === 0) return

    let body = {
      conversationTitle: 'test',
      items: conversation,
    }

    invokePost('Conversations/' + siteSelected?.name, body, (x: any) => {
      console.log('post convo response', x)
    })
  }

  return (
    <div className="App">
      <div className="FilterList">
        <RaccoonLogo />
        <Space h="xl" />
        <Accordion
          onChange={(x: string) => {
            setActiveAccordian(x)
          }}
          value={activeAccordian}
          defaultValue="WLSite"
          variant="contained"
        >
          <Accordion.Item value="WLSite">
            <Accordion.Control id="bingusballs">
              <IconSitemap />
              Site
            </Accordion.Control>
            <Accordion.Panel>
              <SitePicker
                selectedItem={siteSelected}
                selectItemCallback={(x: any) => {
                  setSiteSelected(x)
                }}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="dates">
            <Accordion.Control disabled={siteSelected == null}>
              <IconCalendar /> Date range
            </Accordion.Control>
            <Accordion.Panel>
              <RawTimeEntry
                label="Start Date"
                selectedDate={startDate}
                callback={setStartDate}
                site={siteSelected}
              />
              <Space h="sm" />
              <RawTimeEntry
                label="End Date"
                selectedDate={endDate}
                callback={setEndDate}
                site={siteSelected}
              />
              <Space h="sm" />
              <Divider />
              <Space h="xs" />
              <Text fw={300} fz="sm">
                All dates are{' '}
                <a
                  href="https://www.timeanddate.com/worldclock/timezone/utc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  UTC
                </a>
                {' and formatted as '}
                <a href="https://www.iso.org/iso-8601-date-and-time-format.html">
                  ISO 8601
                </a>{' '}
                format
              </Text>
              <Text fw={300} fz="sm">
                {'You are ' +
                  Math.abs(timeDiff) +
                  ' hours ' +
                  (timeDiff > 0 ? 'behind ' : 'ahead of ') +
                  ' UTC'}
              </Text>
              <Space h="xs" />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="Users">
            <Accordion.Control disabled={siteSelected == null}>
              <IconUsers /> Usernames
            </Accordion.Control>
            <Accordion.Panel>
              <UsernamePicker
                site={siteSelected}
                usernames={usersSelected}
                setUsernames={setUsersSelected}
                siteUsers={siteUsers}
                setSiteUsers={setSiteUsers}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
        <Space h="xl" />
        <div className="BottomButton">
          <Button
            w="100%"
            color="green"
            onClick={doFreshSearch}
            disabled={disableSearchButton}
          >
            Search
          </Button>
        </div>
        <div className="Notice">
          <Text size={10} color="grey" className="Notice">
            This project is not associated with, authorized, or endorsed by
            WhiteLeaf or WhiteForest. Raccoon, Magnifier, and File icons
            provided by www.flaticon.com.
            <Space h="xs" />
            <div className="LegaleseRow">
              <a href="/attributions">Attributions</a>
              {' | '}
              <a href="/termsofservice">Terms of Service</a>
              {' | '}
              <a href="/privacy">Privacy</a>
            </div>
          </Text>
        </div>
      </div>
      <div className="LogDisplay">
        <NotificationDisplay notifications={notifications} />
        <div className="LogDisplayTermSearch">
          <TextInput
            id="TermSearchInput"
            ref={highlightBoxRef}
            placeholder="Highlight Term"
            variant="filled"
            disabled={messages.length === 0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') updateHighlight()
            }}
            onChange={(e) => {
              if (e.target.value === '') setHighlightTerm(null)
            }}
          />
          <Button
            id="HighlightTermBtn"
            onClick={updateHighlight}
            disabled={messages.length === 0}
          >
            Highlight Term
          </Button>
        </div>

        <div className="MainContent">
          <LogItemDisplay
            messages={messages}
            siteUsers={siteUsers}
            siteEmotes={siteEmotes}
            selectMessageCbx={modifyConversation}
            highlightTerm={highlightTerm}
          />
        </div>
        <Pagination
          id="LogItemDisplayPaginator"
          page={activePage}
          onChange={setPage}
          color="green"
          total={queryCount / pageIncrement}
          disabled={messages === null}
        />
      </div>
      <Space h="xl" />
      <div className="ConversationLog">
        <ConversationDisplay
          items={conversationMessages}
          clearConversation={clearConversation}
          modifyConversation={modifyConversation}
          saveConversation={saveConversation}
        />
      </div>
    </div>
  )
}

export default ConversationBuilder
