import { useEffect, useState } from 'react'
import { Text } from '@mantine/core'
import { motion, stagger, useAnimate } from 'framer-motion'
import HeadImg from '../../raccheadnoears.png'
import LeftEar from '../../leftear.png'
import RightEar from '../../rightear.png'
import HandImg from '../../racchand.png'
import DocumentIcon from '../../document.png'
import './RaccoonLogo.css'

function RaccoonLogo(props: any) {
  const [isAnimating, setIsAnimating] = useState(false)

  return (
    <div className="LogoHeader">
      <motion.div
        className="LogoImage"
        onHoverStart={() => setIsAnimating(true)}
        onHoverEnd={() => setIsAnimating(false)}
      >
        <motion.img
          id="raccoonearleft"
          src={LeftEar}
          animate={{
            rotate: isAnimating ? 5 : 0,
            translateX: isAnimating ? 3 : 0,
          }}
        />
        <motion.img
          id="raccoonearright"
          src={RightEar}
          animate={{
            rotate: isAnimating ? 15 : 0,
          }}
        />
        <motion.img
          id="raccoonhead"
          src={HeadImg}
          animate={{
            rotate: isAnimating ? 12 : 0,
            transition: { bounce: 0.5 },
          }}
        />
        <motion.img
          id="documentIcon"
          src={DocumentIcon}
          animate={{
            translateX: 36,
            translateY: 30,
            rotateZ: isAnimating ? 35 : 15,
            opacity: isAnimating ? 1 : 0,
            transition: { power: 10 },
          }}
        />
        <motion.img
          id="raccoonhand"
          src={HandImg}
          animate={{
            rotate: isAnimating ? -15 : 0,
            translateX: isAnimating ? 1 : 0,
            translateY: isAnimating ? -1 : 0,
            transition: { type: 'spring', velocity: 1 },
          }}
        />
      </motion.div>
      <Text fz="xl" title="Not associated with WhiteLeaf or WhiteForest">
        WhiteLeaf Log Viewer
      </Text>
    </div>
  )
}

export default RaccoonLogo
