import { Routes, Route, useLocation } from 'react-router-dom'
import ConversationBuilder from './ConversationBuilder'
import ConversationViewer from './ConversationViewer'

function parseQueryString(queryString: string): { [key: string]: string } {
  const params: { [key: string]: string } = {}
  const keyValuePairs = queryString.slice(1).split('&')

  keyValuePairs.forEach((keyValuePair) => {
    const [key, value] = keyValuePair.split('=')
    const decodedKey = decodeURIComponent(key)
    const decodedValue = decodeURIComponent(value)
    params[decodedKey] = decodedValue
  })

  return params
}

const App = () => {
  const location = useLocation()
  const qs = parseQueryString(location.search)
  return (
    <div>
      <Routes>
        <Route path="/" element={<ConversationBuilder queryString={qs} />} />
        <Route
          path="/view"
          element={<ConversationViewer queryString={qs} />}
        />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  )
}

export default App
