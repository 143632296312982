import {
  Button,
  CloseButton,
  Divider,
  Paper,
  Space,
  Text,
  Title,
} from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import { ILogItem } from '../LogItemDisplay/LogItem'
import './ConversationDisplay.css'

export interface IConversationDisplayProps {
  items: ILogItem[]
  clearConversation: any
  modifyConversation: any
  saveConversation: any
}

function ConversationDisplay(props: IConversationDisplayProps) {
  // console.log('conversation display props', props)

  const clipboard = useClipboard({ timeout: 500 })

  const copyToClipboard = () => {
    let str = props.items
      .map((x) => {
        return '[' + x.time + '] ' + x.username + ': ' + x.message
      })
      .join('\n')

    console.log(str)
    clipboard.copy(str)
  }

  let convoItems: any = <p>Select messages to start building a conversation</p>
  if (props.items.length !== 0) {
    convoItems = props.items.map((x) => (
      <Paper
        className="ConversationLogItem"
        shadow="sm"
        radius="sm"
        p="xs"
        withBorder
        key={x.time + x.message + Date.now() + Math.random()}
      >
        <div className="CardRow">
          <Title size={15}>{x.username}</Title>
          <CloseButton
            onClick={() => {
              // console.log('Clearing item', x)
              props.modifyConversation(x, true)
            }}
          />
        </div>
        <Title size={12}>{x.time.toString()}</Title>
        <Space h="xs" />
        <Text>{x.message}</Text>
      </Paper>
    ))
  }

  return (
    <div className="ConversationDisplay">
      <div className="ConversationDisplayTitleRow">
        <Text id="ConversationBuilderTitle" fz="xl">
          Conversation Builder
        </Text>
        <Button
          variant="subtle"
          color="red"
          disabled={props.items.length === 0}
          onClick={props.clearConversation}
        >
          Clear All
        </Button>
      </div>
      <Space h="xs" />
      <Divider color={'#e9e9e9'} />
      <Space h="xs" />
      <div className="ConversationList">{convoItems}</div>
      <Space h="xl" />
      <Divider color={'#e9e9e9'} />
      <Space h="xl" />
      <div id="ConvoOptionButtons">
        <Button
          id="SaveConvo"
          variant="subtle"
          color="green"
          onClick={() => props.saveConversation(props.items)}
        >
          Save Conversation
        </Button>
        <Button id="ExportConvo" color="green" onClick={copyToClipboard}>
          Copy to Clipboard
        </Button>
      </div>
    </div>
  )
}

export default ConversationDisplay
