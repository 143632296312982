const CheckDate = (newDate: string) => {
  var startTimestamp = Date.parse(newDate)
  if (isNaN(startTimestamp) == false) {
    var d = new Date(startTimestamp)
    // console.log('Valid date ', d)
    return true
  }
  return false
}

export default CheckDate
