import { TextInput, Button, Menu, Text, Divider } from '@mantine/core'
import { useEffect, useState } from 'react'
import WLSite from '../../types/WLSite'
import CheckDate from '../../util/TimeHelper'
import './RawTimeEntry.css'

interface IRawTimeEntryProps {
  label: string
  site: WLSite | null
  callback: any
  selectedDate: Date | null
}

function AddTime(
  date: Date,
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
) {
  console.log('ADDTIME', date, days, hours, minutes, seconds)

  const newDay = date.getDate() + days
  date.setDate(newDay)
  const newHours = date.getHours() + hours
  date.setHours(newHours)
  const newMinutes = date.getMinutes() + minutes
  date.setMinutes(newMinutes)
  const newSeconds = date.getSeconds() + seconds
  date.setSeconds(newSeconds)

  return date.toISOString()
}

function RawTimeEntry(props: IRawTimeEntryProps) {
  let [selectDate, setselectDate] = useState<string>('')

  let isValid = selectDate && CheckDate(selectDate)

  let [showMenu, setShowMenu] = useState<boolean>(false)

  useEffect(() => {
    // console.log('selectedDate props changed', props.selectedDate.toISOString())
    setselectDate(
      props.selectedDate?.toISOString() ? props.selectedDate.toISOString() : '',
    )
  }, [props.selectedDate])

  useEffect(() => {
    // console.log('manual date changed', selectDate)
    if (isValid && selectDate != props.selectedDate?.toISOString()) {
      /*console.log('manual date entry doesnt match props, calling callback')
      console.log('props', props.selectedDate.toISOString())
      console.log('manual', selectDate)*/
      props.callback(new Date(selectDate))
    }
  }, [selectDate])

  return (
    <div className="RawTimeEntry">
      <TextInput
        className="RTETextInput"
        label={props.label}
        value={selectDate}
        onChange={(e) => setselectDate(e.target.value)}
        error={!isValid}
      />
      <Menu shadow="xl" trigger="hover" openDelay={100} closeDelay={400}>
        <Menu.Target>
          <Button
            className="RTEAdjustButton"
            onClick={() => {
              setShowMenu(!showMenu)
            }}
            variant="light"
          >
            Edit
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Edit {props.label}</Menu.Label>
          <div className="AdjustBtnRow">
            <Text color="dimgray" className="AdjustRowText">
              Add Days
            </Text>
            <Button
              variant="light"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), -1, 0, 0, 0))
              }}
            >
              -
            </Button>
            <Button
              variant="light"
              color="green"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 1, 0, 0, 0))
              }}
            >
              +
            </Button>
          </div>
          <Divider />
          <div className="AdjustBtnRow">
            <Text color="dimgray" className="AdjustRowText">
              Add Hours
            </Text>
            <Button
              variant="light"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, -1, 0, 0))
              }}
            >
              -
            </Button>
            <Button
              variant="light"
              color="green"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, 1, 0, 0))
              }}
            >
              +
            </Button>
          </div>
          <Divider />
          <div className="AdjustBtnRow">
            <Text color="dimgray" className="AdjustRowText">
              Add Minutes
            </Text>
            <Button
              variant="light"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, 0, -1, 0))
              }}
            >
              -
            </Button>
            <Button
              variant="light"
              color="green"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, 0, 1, 0))
              }}
            >
              +
            </Button>
          </div>
          <Divider />
          <div className="AdjustBtnRow">
            <Text color="dimgray" className="AdjustRowText">
              Add Seconds
            </Text>
            <Button
              variant="light"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, 0, 0, -1))
              }}
            >
              -
            </Button>
            <Button
              variant="light"
              color="green"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(AddTime(new Date(selectDate), 0, 0, 0, 1))
              }}
            >
              +
            </Button>
          </div>
          <Divider />
          <div className="AdjustBtnRow">
            <Text color="dimgray" className="AdjustRowText">
              Reset Time 
            </Text>
            <Button
              variant="light"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                let date = new Date(Date.now())

                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                date.setMilliseconds(0)

                setselectDate(date.toISOString())
              }}
            >
              Start of Day
            </Button>
            <Button
              variant="subtle"
              color="red"
              className="AdjustRowButton"
              onClick={() => {
                setselectDate(new Date().toISOString())
              }}
            >
              Now
            </Button>
          </div>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}

export default RawTimeEntry
