import { useState } from 'react'
import './ConversationViewer.css'
import { LogItemLine } from './components/LogItemDisplay/LogItemDisplay'
import { invokeGet } from './util/HttpHelper'

interface IConversationViewerProps {
  queryString: any
}

interface IConversationItem {} // server resp type

interface IConversationContent {
  ConversationTitle: string
  ConversationItems: any[]
}

const ConversationViewer = (props: IConversationViewerProps) => {
  const guidRegex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i
  const validQueryGUID =
    props.queryString &&
    props.queryString.c &&
    guidRegex.test(props.queryString.c)

  let [conversationTitle, setConversationTitle] = useState<string>(
    'Please wait :)',
  )
  let [conversation, setConversation] = useState<IConversationContent | null>(
    null,
  )

  if (validQueryGUID && conversation === null) {
    console.log('Getting conversation with id', props.queryString.c)
    setConversation({ ConversationItems: [], ConversationTitle: '' })
    invokeGet('Conversations/' + props.queryString.c, (x: any) => {
      console.log(x)
      setConversationTitle(
        'Conversation | ' + x.site + ' | "' + x.conversationTitle + '"',
      )
    })
  }

  let thing: any = <p>Nothing to show :(</p>

  if (conversation) {
    thing = (
      <div>
        {conversation.ConversationItems.map((x, i) => {
          // hacked together, replace with literally anything else lmao
          return LogItemLine(
            x,
            [],
            [],
            [],
            (x: any) => {},
            [],
            (x: any) => {},
            (x: any) => {},
            null,
          )
        })}
      </div>
    )
  }

  return (
    <div className="ConvoViewerMain">
      <div className="ConvoTitleBar">
        <h3 id="ConversationTitle">{conversationTitle}</h3>
      </div>
      <div className="ConvoBodyMain">
        <div className="ConversationBody">{thing}</div>
      </div>
    </div>
  )
}

export default ConversationViewer

/*  if (props.messages === null || props.messages.length === 0) {
    return (
      <div className="LogItemDisplay">
        <p id="nothingtoshow">Nothing to show :(</p>
      </div>
    )
  }

  return (
    <div className="LogItemDisplay" ref={scrollRef}>
      {props.messages.map((x, i) => {
        return LogItemLine(
          x,
          props.siteUsers,
          props.siteEmotes,
          selectedUser,
          setSelectedUser,
          highlightedUser,
          setHighlightedUser,
          props.selectMessageCbx,
        )
      })}
    </div>*/
