import { Text } from '@mantine/core'
import './LogItem.css'

export interface ILogItem {
  id: any
  username: string
  message: string
  site: string
  time: Date
  hash: string
}

export interface ILogItemProps {
  time: string
  username: string
  message: string
  selectedUsername: string[] | null
  selectUserCbx?: any
  highlightedUsername: string[] | null
  highlightUserCbx: any
  siteUsers: string[]
  siteEmotes: string[]
  highlightTerm: string | null
}

// fml, thanks to IHateRegex <3
const urlRegex = /((http|https):\/\/)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/
const urlRegex2 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/

function highlightMessageText(message: any[], highlightText: string) {
  let splitHighlightText = highlightText.split(' ')
  if (
    highlightText === '' ||
    splitHighlightText.length === 0 ||
    message.length === 0 ||
    splitHighlightText.length > message.length
  ) {
    return message
  }

  for (let i: number = 0; i < message.length - splitHighlightText.length; i++) {
    let match: boolean = true
    let msgText = ''

    for (let j: number = 0; j < splitHighlightText.length && match; j++) {
      msgText = message[i + j]
      if (typeof msgText !== 'string') {
        match = false
        continue
      }
      msgText = msgText.slice(0, msgText.length - 1)
      if (msgText.toLowerCase() !== splitHighlightText[j].toLowerCase())
        match = false
    }

    if (match) {
      message.splice(
        i,
        splitHighlightText.length,
        <span key={highlightText + Date.now() + Math.random()}>
          <span className="LogItemHighlightedText">{msgText}</span>{' '}
        </span>,
      )
    }
  }

  return message
}

function LogItem(props: ILogItemProps) {
  // console.log(props.highlightTerm)

  let processedMessage = props.message.split(' ').map((x, i) => {
    if (urlRegex.test(x) || urlRegex2.test(x)) {
      return (
        <span key={x + Date.now() + Math.random()}>
          <a href={x} target="_blank">
            {x}
          </a>{' '}
        </span>
      )
    } else if (x[0] === '!' && x.length > 1) {
      return (
        <span
          className="LogItemTextCommand"
          title="Chat Command"
          key={x + Date.now() + Math.random()}
        >
          {x}{' '}
        </span>
      )
    } else if (i == 0 && x[0] === '/') {
      return (
        <span
          className="LogItemTextSlashCommand"
          title="Slash Command or tone indicator idk"
          key={x + Date.now() + Math.random()}
        >
          {x}{' '}
        </span>
      )
    } else if (props.siteUsers.includes(x.replace('@', ''))) {
      return (
        <span key={x + Date.now() + Math.random()}>
          <span
            className="LogItemTextMentionUsername"
            title={
              x.includes('@')
                ? 'Chat user, ' + props.username + ' is dumb and using @'
                : 'Chat user'
            }
          >
            {x}
          </span>{' '}
        </span>
      )
    } else if (props.siteEmotes.includes(x.split(':')[0])) {
      return (
        <span key={x + Date.now() + Math.random()}>
          <span className="LogItemEmote" title="Emote">
            {x}
          </span>{' '}
        </span>
      )
    } else return x + ' '
  })
  // console.log(props.highlightedUsername, props.username)

  if (props.highlightTerm) {
    processedMessage = highlightMessageText(
      processedMessage,
      props.highlightTerm,
    )
  }

  return (
    <Text className="LogItemText" key={props.time + props.message}>
      <span className={'LogItemDate'}>[{props.time.toString()}]</span>{' '}
      <span
        className={'LogItemUsername'}
        title={'Highlight user ' + props.username}
        onClick={() => {
          // console.log('Highlighting User: ' + props.username)
          props.highlightUserCbx(props.username)
        }}
        onContextMenu={(event) => {
          // console.log(event)
          event.preventDefault()
        }}
      >
        {props.username}
      </span>
      : <span className="LogItemMessage">{processedMessage}</span>
    </Text>
  )
}

export default LogItem
