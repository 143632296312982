import React, { useEffect } from 'react'
import { useState } from 'react'
import LogItem, { ILogItem } from './LogItem'
import './LogItemDisplay.css'

interface ILogItemDisplayProps {
  messages: ILogItem[]
  siteUsers: string[]
  siteEmotes: string[]
  callback?: any
  selectMessageCbx: any
  highlightTerm: string | null
}

export function LogItemLine(
  item: ILogItem,
  siteUsers: string[],
  siteEmotes: string[],
  selectedUser: string[],
  setSelectedUser: any,
  highlightedUser: string[],
  setHighlightedUser: any,
  selectMessageCbx: any,
  highlightTerm: string | null,
) {
  return (
    <div
      className="LogItemCard"
      key={item.hash}
      onClick={() => selectMessageCbx(item)}
    >
      <LogItem
        time={item.time.toString()}
        username={item.username}
        message={item.message}
        selectedUsername={selectedUser}
        selectUserCbx={setSelectedUser}
        highlightedUsername={highlightedUser}
        highlightUserCbx={setHighlightedUser}
        siteUsers={siteUsers}
        siteEmotes={siteEmotes}
        highlightTerm={highlightTerm}
        key={Date.now() + '' + item.time + item.username + item.message}
      />
    </div>
  )
}

function LogItemDisplay(props: ILogItemDisplayProps) {
  let scrollRef = React.useRef<any>() // yeah yeah I know
  let [highlightedUser, setHighlightedUser] = useState([])
  let [selectedUser, setSelectedUser] = useState([])

  useEffect(() => {
    if (props.messages !== null && props.messages.length !== 0) {
      scrollRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [props.messages])

  if (props.messages === null || props.messages.length === 0) {
    return (
      <div className="LogItemDisplay">
        <p id="nothingtoshow">Nothing to show :(</p>
      </div>
    )
  }

  return (
    <div className="LogItemDisplay" ref={scrollRef}>
      {props.messages.map((x, i) => {
        return LogItemLine(
          x,
          props.siteUsers,
          props.siteEmotes,
          selectedUser,
          setSelectedUser,
          highlightedUser,
          setHighlightedUser,
          props.selectMessageCbx,
          props.highlightTerm,
        )
      })}
    </div>
  )
}

export default LogItemDisplay
