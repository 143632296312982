import './NotificationDisplay.css'

interface INotificationDisplayProps {
  notifications: any[]
}

function NotificationDisplay(props: INotificationDisplayProps) {
  // console.log('Notifications: ', props.notifications)
  return (
    <div className="NotificationDisplay">
      {props.notifications.map((x) => {
        // console.log('notification:', x)
        return x
      })}
    </div>
  )
}

export default NotificationDisplay
