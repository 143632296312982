import { Avatar, Group, Select, SelectItem, Text } from '@mantine/core'
import { useState, useEffect, forwardRef } from 'react'
import WLSite from '../../types/WLSite'
import { invokeGet } from '../../util/HttpHelper'
import './SitePicker.css'

interface ISitePickerProps {
  selectedItem: any
  selectItemCallback: any
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string
  label: string
  description: string
}

function SitePicker(props: ISitePickerProps) {
  let [siteList, setSiteList] = useState<WLSite[]>([])

  // load sitelist
  useEffect(() => {
    console.log('getting sitelist')
    invokeGet('WLSite', (x: WLSite[]) => setSiteList(x))
  }, [])

  let siteOptions: SelectItem[] = []
  if (siteList.length > 0) {
    siteOptions = siteList.map((x) => {
      return {
        key: x.name,
        value: x.name,
        image: x.iconURL,
        label: x.name,
      }
    })
  }

  const findSelectedSite = (siteName: string | null) => {
    let val = null
    if (siteName === null) return null

    siteList.map((x) => {
      if (x.name === siteName) {
        // console.log('Found matching item')
        val = x
      }
    })

    return val
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} />

          <div>
            <Text size="sm">{label}</Text>
          </div>
        </Group>
      </div>
    ),
  )

  return (
    <div className="SitePicker">
      <Select
        label="Select a WhiteLeaf site"
        data={siteOptions}
        value={props.selectedItem?.name}
        itemComponent={SelectItem}
        onChange={(x) => {
          props.selectItemCallback(findSelectedSite(x))
        }}
      />
    </div>
  )
}

export default SitePicker
