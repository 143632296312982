import { Text, Chip, MultiSelect, Space } from '@mantine/core'
import { useState, useEffect } from 'react'
import { invokeGet } from '../../util/HttpHelper'
import { IconUserPlus } from '@tabler/icons'
import WLSite from '../../types/WLSite'
import './UsernamePicker.css'

interface IUsernamePickerProps {
  usernames: string[]
  setUsernames: any // callback
  site: WLSite | null
  siteUsers: any
  setSiteUsers: any
}

function getUsernames(siteName: string, cbx: any) {
  // console.log('Getting users on site:', siteName)
  invokeGet('Users?siteName=' + siteName, (x: any) => {
    // console.log('Got resp: ', x)
    cbx(x)
  })
}

function UsernamePicker(props: IUsernamePickerProps) {
  let [selectedUsers, setSelectedUsers] = useState<string[]>([])

  useEffect(() => {
    if (props.site === null) {
      props.setSiteUsers([])
      props.setUsernames([])
      return
    }

    getUsernames(props.site.name, (users: any) => {
      props.setSiteUsers(users)
    })
  }, [props.site])

  return (
    <div className="UsernamePicker">
      <MultiSelect
        data={props.siteUsers}
        clearable
        searchable
        limit={20}
        clearButtonLabel="Clear selection"
        icon={<IconUserPlus />}
        onChange={(x) => props.setUsernames(x)}
      />
      <Text
        fw={300}
        fz="sm"
        title="We can only display users who sent a message during a cataloged day. If a user you are expecting is not available, request logs from a date in which they were active"
      >
        Searching for {selectedUsers.length} of {props.siteUsers.length} users*
      </Text>
    </div>
  )
}

export default UsernamePicker
